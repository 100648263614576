<template>
  <div class="mail_warp">
    <div class="mail_content">
      <div class="mail_left">
        <div class="title">通讯录</div>
        <!-- <a-input-search
          class="search_input"
          @search="handleSearch"
          placeholder="输入机构名称查询..."
        /> -->
        <!-- <div class="tree_title">公司通讯录</div> -->
        <a-tree
          checkStrictly
          :expandedKeys.sync="expandedKeys"
          :selectedKeys="selectedKeys"
          :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
          :treeData="treeDataSource"
          @select="handleTreeSelect"
        >
          <a-icon slot="switcherIcon" type="down" />
        </a-tree>
        <div class="hotline" @click="openpage">
          <a-icon class="icon" type="phone"/> <span>服务热线</span>
        </div>
      </div>
      <div class="mail_right">
        <!-- 搜索区域 -->
        <div class="search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="24">
              <a-col
                :md="6"
                :sm="8"
              >
                <a-form-item>
                  <a-input-search class="search_input" placeholder="联系人,电话，邮箱查询" v-model="queryParam.realname" style="width: 200px" @search="searchQuery" />
                  <!-- <a-input
                    class="search_input"
                    v-model="queryParam.realname"
                    placeholder="联系人,电话，邮箱查询"
                  /> -->
                </a-form-item>
              </a-col>
              <a-col
                :md="6"
                :sm="8"
              >
                <span
                  style="float: left;overflow: hidden;"
                  class="table-page-search-submitButtons"
                >
                  <a-button
                    class="search_btn search_query"
                    type="primary"
                    @click="searchQuery"
                  >查询</a-button>
                  <a-button
                    class="search_btn"
                    style="margin-left: 8px"
                    @click="searchReset"
                  >重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- table 区域 -->
        <div class="search-table">
          <a-table
            ref="table"
            rowKey="userId"
            size="middle"
            :columns="columns"
            :data-source="dataSource"
            :pagination="ipagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
          </a-table>
        </div>
      </div>
    </div>
    <server-line ref="lineForm" />
  </div>
</template>
<script>
import { queryDepartTreeList } from '@/api/login'
import { JeecgListMixin } from '@/views/mixins/mixin'
import { getAction } from '@/api/manage'
import serverLine from '@/views/serverLine'

export default {
  mixins: [JeecgListMixin],
  components: {serverLine},
  data() {
    return {
      cardLoading: true,
      loading: false,
      autoExpandParent:true,
      treeDataSource: [],
      treeExpandData: [],
      selectedKeys: [],
      expandedKeys: [],
      positionInfo: {},
      ipagination:{
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      orgCode: '',
      columns: [
        {
          title: '联系人姓名',
          width: '15%',
          align: 'center',
          dataIndex: 'realname'
        },
        {
          title: '所在部门',
          width: '15%',
          align: 'center',
          dataIndex: 'departName'
        },
        {
          title: '联系电话',
          width: '15%',
          align: 'center',
          dataIndex: 'phone'
        },
        {
          title: '联系邮箱',
          width: '15%',
          align: 'center',
          dataIndex: 'email'
        }
      ],
      url: {
        list: '/mesnac/sys/user/queryByOrgCodeForAddressList',
        listByPosition: '/mesnac/sys/position/list'
      }
    }
  },
  created() {
    this.queryPositionInfo()
    this.queryTreeData()
  },
  methods: {
    openpage(){
        this.$refs.lineForm.open()
    },
    loadData(pageNum, orgCode) {
      this.loading = true
      if (pageNum === 1) {
        this.ipagination.current = 1
      }
      // update-begin- --- author:wangshuai ------ date:20200102 ---- for:传过来的部门编码为空全查
      if (!orgCode) {
        getAction(this.url.list, {
          ...this.getQueryParams()
        })
          .then(res => {
            if (res.success) {
              this.dataSource = res.result.records
              this.ipagination.total = res.result.total
            }
          })
          .finally(() => {
            this.loading = false
            this.cardLoading = false
          })
        // update-end- --- author:wangshuai ------ date:20200102 ---- for:传过来的部门编码为空全查
      } else {
        //加载数据 若传入参数1则加载第一页的内容
        getAction(this.url.list, {
          orgCode,
          ...this.getQueryParams()
        })
          .then(res => {
            if (res.success) {
              this.dataSource = res.result.records
              this.ipagination.total = res.result.total
            }
          })
          .finally(() => {
            this.loading = false
            this.cardLoading = false
          })
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log('pagination: ', pagination)
      //分页、排序、筛选变化时触发
      //TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc"
      }
      this.ipagination = pagination;
      console.log('s')
      this.loadData('', this.orgCode);
    },
    searchQuery() {
      this.loadData(1, this.value)
    },
    searchReset() {
      this.queryParam = {}
      this.orgCode = ''
      this.loadData(1, this.value)
    },

    // 查询职务信息
    queryPositionInfo() {
      getAction(this.url.listByPosition, { pageSize: 99999 }).then(res => {
        if (res.success) {
          let positionInfo = {}
          res.result.records.forEach(record => {
            positionInfo[record['code']] = record['name']
          })
          this.positionInfo = positionInfo
        }
      })
    },
    queryTreeData(keyword) {
      this.commonRequestThen(
        queryDepartTreeList({
          departName: keyword ? keyword : undefined
        })
      )
    },
    handleSearch(value) {
      console.log('value: ', value);
      if (value) {
        getAction('/mesnac/sys/sysDepart/searchBy', { keyWord: value }).then(res => {
          console.log(res)
        })
        // searchByKeywords({ keyWord: value }).then(res => {
        //     console.log(res)
        //   })
        // this.commonRequestThen(searchByKeywords({ keyWord: value }))
      } else {
        this.queryTreeData()
      }
    },
    handleTreeSelect(selectedKeys, event) {
      console.log('selectedKeys: ', selectedKeys);
      if (selectedKeys.length > 0 && this.selectedKeys[0] !== selectedKeys[0]) {
        this.selectedKeys = [selectedKeys[0]]
        let orgCode = event.node.dataRef.orgCode
        this.orgCode = orgCode
        this.loadData(1, orgCode)
      }
    },
    commonRequestThen(promise) {
      console.log('promise: ', promise);
      this.loading = true
      promise
        .then(res => {
          if (res.success) {
            // update-begin- --- author:wangshuai ------ date:20200102 ---- for:去除默认选中第一条数据、默认展开所有第一级
            // 默认选中第一条数据、默认展开所有第一级
            if (res.result.length > 0) {
              this.expandedKeys = []
              res.result.forEach((item, index) => {
                if (index === 0) {
                  this.selectedKeys = ['c2954815ba614cefb5db933ddf965840']
                  this.loadData(1,'公司高管')
                }
                this.expandedKeys.push(item.id)
              })
            }
            this.treeDataSource = res.result
            // update-end- --- author:wangshuai ------ date:20200102 ---- for:去除默认选中第一条数据、默认展开所有第一级
          } else {
            this.$message.warn(res.message)
            console.error('组织机构查询失败:', res)
          }
        })
        .finally(() => {
          this.loading = false
          this.cardLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.mail_warp {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  background: #f6f6f6;
  .mail_content {
    width: 1560px;
    margin: 0 auto;
    display: flex;
    min-height: 800px;
    .mail_left {
      width: 300px;
      background: #ffecec 4800%;
      text-align: center;
      .hotline{
        text-align: left;
        margin-left: 25px;
        font-size: 16px;
        color: rgba(16, 16, 16, 82);
        cursor: pointer;
        .icon{
          color: #F4A409;
          margin-right: 5px;
        }
      }
      .title {
        margin-top: 30px;
        margin-left: 20px;
        font-size: 22px;
        color: rgba(16, 16, 16, 100);
        font-weight: 600;
        text-align: left;
      }
      .tree_title {
        text-align: left;
        font-size: 14px;
        margin: 20px 0px 10px 20px;
      }
      .search_input {
        width: 200px;
        margin: 0 auto;
        margin-top: 30px;
        height: 46px;
        /deep/.ant-input{
          height: 46px;
          line-height: 46px;
          border: 1px solid rgba(248, 218, 218, 100);
          border-radius: 20px;
        }
      }
      /deep/ .ant-tree{
        margin: 0 10px;
        li:last-child{
          text-align: left;
        }
        li .ant-tree-node-content-wrapper.ant-tree-node-selected {
          background-color: #ffecec;
        }
        li .ant-tree-node-content-wrapper:hover {
          background-color: #ffecec;
        }
        .ant-tree-treenode-selected{
          border: 1px solid #BC0039;
          border-radius: 20px;
        }
        li span.ant-tree-switcher {
          color: #BC0039;
        }


      }
    }
    .mail_right {
      width: 1260px;
      padding-top: 30px;
      background: #fff;
      border-left: 1px solid #bc0039;
      .search-wrapper {
        margin-left: 20px;
        /deep/.ant-form-item-control {
          .ant-calendar-picker {
            .ant-calendar-picker-input {
              height: 40px;
              border: 1px solid rgba(188, 0, 57, 100);
              border-radius: 10px;
              color: rgba(188, 0, 57, 100);
              font-size: 14px;
              &::-webkit-input-placeholder,
              textarea::-webkit-input-placeholder {
                color: rgba(188, 0, 57, 100);
              }
            }
            .anticon {
              color: #bc0039;
            }
          }
        }
        .search_input {
          width: 200px;
          border: 1px solid rgba(188, 0, 57, 100);
          border-radius: 10px;
          height: 40px;
          /deep/.ant-input{
            height: 38px;
            height: 38px;
            color: rgba(188, 0, 57, 100);
            border: 1px solid rgba(248, 218, 218, 100);
            border-radius: 10px;
            font-size: 14px;
            &::-webkit-input-placeholder,
            textarea::-webkit-input-placeholder {
              color: rgba(188, 0, 57, 100);
            }
            &:focus{
              border: 1px solid rgba(248, 218, 218, 100);
              box-shadow:none;
            }
          }
        }
        .search_btn {
          width: 67px;
          height: 40px;
          margin-top: 5px;
          text-align: center;
          font-size: 16px;
          color: #bc0039;
          border: 1px solid #bc0039;
          border-radius: 10px;
          padding: 0px;
        }
        .search_query {
          background-color: #ffeded;
        }
      }
      .search-table {
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
        /deep/.ant-pagination {
          .ant-pagination-next,
          .ant-pagination-prev {
            .ant-pagination-item-link {
              &:hover {
                color: #bc0039;
              }
            }
          }
          .ant-pagination-next:focus .ant-pagination-item-link {
            color: #bc0039;
          }
          .ant-pagination-item {
            a:hover {
              color: #bc0039;
            }
          }
          .ant-pagination-item-active {
            border-color: #bc0039;
            a {
              color: #bc0039;
            }
          }
        }
      }
    }
  }
}
</style>

